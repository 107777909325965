import { Dialog, useMediaQuery } from '@mui/material';

import { palette, theme } from '../../theme';
import { Icon } from '../Icon';
import { UnorderedList } from '../UnorderedList';
import {
    StyledDialogTitle,
    StyledDialogContent,
    StyledCloseButton,
    StyledIcon,
    StyledHeading,
    OverlayContentContainer,
    StyledBenefitsList,
    StyledBenefitHeading,
    StyledOverlayHeading,
    StyledBenefitsItem,
    StyledHorizontalRule,
    StyledSection,
    StyledSectionHeading,
    StyledListItem,
    StyledParagraph,
    StyledBoldText,
    StyledIconContainer,
} from './PinoOverlay.styled';
import { OverlayProperties } from './PinoOverlay.types';

export const PinoOverlay = ({
    title,
    open,
    handleClose,
}: OverlayProperties) => {
    const fullScreen = useMediaQuery('(max-width:767px)');
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconSize = isDesktop ? 34 : 22;

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="overlay"
            aria-describedby="overlay-modal"
            PaperProps={{
                style: {
                    borderRadius: isDesktop ? '1.6rem' : 'unset',
                    maxWidth: isDesktop ? '62.8rem' : '100%',
                },
            }}
        >
            <StyledDialogTitle>
                <StyledHeading variant="h2" component="h1" gutterBottom={false}>
                    {title}
                </StyledHeading>
                <StyledCloseButton
                    onClick={handleClose}
                    aria-label="Close"
                    name="Close"
                >
                    <StyledIcon name="close" color={palette.common.white} />
                </StyledCloseButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                <OverlayContentContainer>
                    <StyledOverlayHeading variant="h3">
                        all of our members benefit from:
                    </StyledOverlayHeading>
                    <StyledBenefitsList>
                        <StyledBenefitsItem>
                            <StyledIconContainer>
                                <Icon
                                    name="dumbells"
                                    size={iconSize}
                                    accessibleTitle="Cost icon"
                                />
                            </StyledIconContainer>
                            <StyledBenefitHeading>top kit</StyledBenefitHeading>
                        </StyledBenefitsItem>
                        <StyledBenefitsItem>
                            <StyledIconContainer>
                                <Icon
                                    name="noContract"
                                    size={iconSize}
                                    accessibleTitle="Contract icon"
                                />
                            </StyledIconContainer>
                            <StyledBenefitHeading>
                                no contract
                            </StyledBenefitHeading>
                        </StyledBenefitsItem>
                        <StyledBenefitsItem>
                            <StyledIconContainer>
                                <Icon
                                    name="classes"
                                    size={iconSize}
                                    accessibleTitle="Classes icon"
                                />
                            </StyledIconContainer>
                            <StyledBenefitHeading>
                                unlimited free classes
                            </StyledBenefitHeading>
                        </StyledBenefitsItem>
                        <StyledBenefitsItem>
                            <StyledIconContainer>
                                <Icon
                                    name="cost"
                                    size={iconSize}
                                    accessibleTitle="Cost icon"
                                />
                            </StyledIconContainer>
                            <StyledBenefitHeading>
                                low-cost memberships
                            </StyledBenefitHeading>
                        </StyledBenefitsItem>
                    </StyledBenefitsList>
                    <StyledHorizontalRule />
                    <StyledSection>
                        <StyledSectionHeading variant="h3">
                            with our ultimate membership, you get even more
                            benefits:
                        </StyledSectionHeading>
                        <UnorderedList>
                            <>
                                <StyledListItem>
                                    <StyledParagraph>
                                        <StyledBoldText>
                                            Access all gyms
                                        </StyledBoldText>{' '}
                                        - Choose from over 230 gyms and access 5
                                        at any one time, so you can workout
                                        whenever you want, wherever you are
                                    </StyledParagraph>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledParagraph>
                                        <StyledBoldText>
                                            Free membership freeze
                                        </StyledBoldText>{' '}
                                        - sometimes life gets in the way.
                                        Ultimate gives you the option to freeze
                                        your monthly membership for up to 1
                                        month at no cost
                                    </StyledParagraph>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledParagraph>
                                        <StyledBoldText>
                                            Bring a friend for free
                                        </StyledBoldText>{' '}
                                        - it&apos;s more fun with a friend and
                                        you can bring a friend for free 4 times
                                        per month, and the same friend 2 times
                                        per month
                                    </StyledParagraph>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledParagraph>
                                        <StyledBoldText>
                                            Free Fiit workouts &amp; plans
                                        </StyledBoldText>{' '}
                                        - take your training to the next level
                                        by accessing 1,000+ workout plans on
                                        this no.1 rated fitness app worth over
                                        £200 annually
                                    </StyledParagraph>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledParagraph>
                                        <StyledBoldText>
                                            Fitness &amp; body composition
                                            tracking
                                        </StyledBoldText>{' '}
                                        - have the best data to track and reach
                                        your goals by using our complimentary
                                        fitness and body composition tracking
                                        (where available)
                                    </StyledParagraph>
                                </StyledListItem>
                                <StyledListItem>
                                    <StyledParagraph>
                                        <StyledBoldText>
                                            Discounts on supplements &amp;
                                            clothing
                                        </StyledBoldText>{' '}
                                        - in addition to your membership, we
                                        also provide discounts and savings on
                                        your favourite health &amp; wellness
                                        brands
                                    </StyledParagraph>
                                </StyledListItem>
                            </>
                        </UnorderedList>
                    </StyledSection>
                </OverlayContentContainer>
            </StyledDialogContent>
        </Dialog>
    );
};
