import {
    Collapse,
    TableBody,
    TableContainer,
    TableFooter,
    TableRow,
    useMediaQuery,
} from '@mui/material';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useMemo, useState } from 'react';

import { palette, theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { Icon } from '../Icon';
import { PinoButton } from '../PinoButton';
import { PinoOverlay } from '../PinoOverlay';
import {
    getAdditionalBenefitButton,
    getHiddenBenefitCount,
    getHiddenBenefits,
    getInitialSelectedProduct,
    getVisibleBenefits,
    getKickerRibbonTitle,
    mapPinoProductsWithDetails,
    scrollToRelatedSection,
    shouldDisableProduct,
} from './PinoTable.helpers';
import { PinoProductWithDetails, PinoTableProperties } from './PinoTable.types';
import { StandardAccessCell } from './StandardAccessCell';
import { pinoProductBenefits } from './pino-table.constants';
import {
    StyledAMonthText,
    StyledAnchorButton,
    StyledBenefitsColumn,
    StyledBoldSpanText,
    StyledCaption,
    StyledDivider,
    StyledExpanderPinoButton,
    StyledButtonWrapperForIcon,
    StyledKickerOfferRibbon,
    StyledKickerOfferRibbonText,
    StyledProductHeader,
    StyledProductHeaderJoiningFee,
    StyledProductHeaderPrices,
    StyledProductTitle,
    StyledSVGOutline,
    StyledTable,
    StyledTableCell,
    StyledTableFooterCell,
    useTableCollapseOverrides,
    StylesTableRow,
} from './pino-table.styled';
import {
    PinoProductTitle,
    BenefitsHeadings,
    productTitle,
} from '@tgg/common-types';
import { DataEventNames, sendAnalyticsDataEvent } from '@tgg/services';
import { decimalToPoundsAndPence } from '@tgg/util';

export const PinoTable = ({
    pinoMonthlyProducts,
    onSelectedProduct,
    tableCaption,
    disabledProduct,
    gymName,
    isOpen24Hours,
    isKickerOffersRibbonEnabled = false,
    isStudentPinoTable = false,
    isDayPassPinoTable = false,
    showFooter = true,
    isProductSelectable = true,
}: PinoTableProperties) => {
    const router = useRouter();
    const onCommerceGymPage = router.asPath.includes('/find-a-gym/');

    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<PinoProductTitle>(
        () =>
            getInitialSelectedProduct(
                disabledProduct,
                isStudentPinoTable,
                isDayPassPinoTable,
            ),
    );
    const [hoveredProduct, setHoveredProduct] =
        useState<PinoProductTitle | null>();
    const overridesCollapseClasses = useTableCollapseOverrides();

    const productBenefits = useMemo(
        () => getVisibleBenefits(isStudentPinoTable, isDayPassPinoTable),
        [isStudentPinoTable, isDayPassPinoTable],
    );
    const hiddenSelectBenefits = useMemo(
        () => getAdditionalBenefitButton(),
        [],
    );
    const hiddenAdditionalBenefits = useMemo(() => getHiddenBenefits(), []);
    const productsWithDetails = mapPinoProductsWithDetails(
        pinoMonthlyProducts,
        pinoProductBenefits,
    );

    const selectProduct = (product: PinoProductWithDetails) => {
        if (isProductSelectable) {
            const { title } = product;

            if (title !== selectedProduct) {
                setSelectedProduct(title);
            }

            if (onSelectedProduct) {
                onSelectedProduct(product);
            }

            sendAnalyticsDataEvent({
                eventName: DataEventNames.PRODUCT_SELECT,
                eventData: product,
                eventGymName: gymName,
            });
        }
    };

    const handleClose = () => setIsModalOpen(false);
    const handleOpen = () => setIsModalOpen(true);

    const handleMouseEnter = (title: PinoProductTitle) => {
        // Only set hover state if on desktop or when mouse if on a different product to the selected
        if (!isDesktop || title === selectedProduct) {
            return;
        }
        setHoveredProduct(title);
    };

    const handleMouseLeave = (title: PinoProductTitle) => {
        // Only set hover state if on desktop or when mouse if on a different product to the selected
        if (!isDesktop || title === selectedProduct) {
            return;
        }
        setHoveredProduct(null);
    };

    return (
        <>
            <TableContainer>
                <StyledTable aria-label="PINO table">
                    <StyledCaption>{tableCaption}</StyledCaption>
                    <TableBody>
                        <TableRow>
                            <StyledBenefitsColumn
                                component="th"
                                scope="col"
                                role="columnheader"
                                $header
                                theme={theme}
                            >
                                Benefits
                                {!isDayPassPinoTable && (
                                    <StyledButtonWrapperForIcon
                                        type="button"
                                        onClick={handleOpen}
                                        aria-label="Benefits information icon"
                                    >
                                        <Icon name="information" size={20} />
                                    </StyledButtonWrapperForIcon>
                                )}
                            </StyledBenefitsColumn>
                            {productsWithDetails.map(product => {
                                const {
                                    id,
                                    price,
                                    title,
                                    joiningFee,
                                    variant,
                                } = product;
                                const isProductDisabled = shouldDisableProduct(
                                    product,
                                    disabledProduct,
                                );

                                const isKickerOfferRibbonShouldBeDisplayed =
                                    isKickerOffersRibbonEnabled &&
                                    !!price.kickerPriceDuration;

                                return (
                                    <StyledProductHeader
                                        theme={theme}
                                        $selectedProduct={
                                            title === selectedProduct
                                                ? title
                                                : undefined
                                        }
                                        onClick={() => {
                                            selectProduct(product);
                                            setSelectedProduct(title);
                                        }}
                                        onMouseEnter={() =>
                                            handleMouseEnter(title)
                                        }
                                        onMouseLeave={() =>
                                            handleMouseLeave(title)
                                        }
                                        key={id}
                                        component="th"
                                        scope="col"
                                        align="center"
                                        role="columnheader"
                                        $isKickerOfferRibbon={
                                            isKickerOfferRibbonShouldBeDisplayed
                                        }
                                        title={title}
                                        disabled={isProductDisabled}
                                    >
                                        {isKickerOfferRibbonShouldBeDisplayed && (
                                            <StyledKickerOfferRibbon>
                                                <StyledKickerOfferRibbonText>
                                                    {getKickerRibbonTitle(
                                                        price.kickerPriceDuration,
                                                    )}
                                                </StyledKickerOfferRibbonText>
                                            </StyledKickerOfferRibbon>
                                        )}

                                        <Icon
                                            name={title}
                                            color={
                                                isStudentPinoTable ||
                                                isDayPassPinoTable
                                                    ? palette.common.white
                                                    : palette.common.blue
                                            }
                                            size={26}
                                        />
                                        <StyledProductTitle
                                            $isStudentPinoTable={
                                                isStudentPinoTable ||
                                                isDayPassPinoTable
                                            }
                                        >
                                            {productTitle[variant] || title}
                                        </StyledProductTitle>
                                        {!isDayPassPinoTable && (
                                            <StyledProductHeaderPrices>
                                                from{' '}
                                                <StyledBoldSpanText>
                                                    {decimalToPoundsAndPence(
                                                        price.value,
                                                    )}
                                                </StyledBoldSpanText>
                                                {!isStudentPinoTable && (
                                                    <StyledAMonthText>
                                                        a month
                                                    </StyledAMonthText>
                                                )}
                                                <StyledDivider
                                                    $product={title}
                                                />
                                                <StyledProductHeaderJoiningFee>
                                                    +{' '}
                                                    {decimalToPoundsAndPence(
                                                        joiningFee || 0,
                                                        false,
                                                    )}{' '}
                                                    joining fee
                                                </StyledProductHeaderJoiningFee>
                                            </StyledProductHeaderPrices>
                                        )}
                                    </StyledProductHeader>
                                );
                            })}
                        </TableRow>
                        {productBenefits.map(heading => (
                            <StylesTableRow
                                key={heading}
                                $showFooter={showFooter}
                            >
                                <StyledBenefitsColumn
                                    theme={theme}
                                    component="th"
                                    scope="row"
                                    role="rowheader"
                                    $innerHeader
                                >
                                    {heading}
                                </StyledBenefitsColumn>
                                {productsWithDetails.map(row => {
                                    const { id, includedBenefits, title } = row;

                                    if (
                                        !includedBenefits[
                                            heading as BenefitsHeadings
                                        ]
                                    ) {
                                        return null;
                                    }

                                    if (
                                        !includedBenefits[
                                            heading as BenefitsHeadings
                                        ].display
                                    ) {
                                        return null;
                                    }

                                    const isProductDisabled =
                                        shouldDisableProduct(
                                            row,
                                            disabledProduct,
                                        );

                                    const view = renderCellContent(
                                        onCommerceGymPage,
                                        includedBenefits[
                                            heading as BenefitsHeadings
                                        ].display,
                                        includedBenefits[
                                            heading as BenefitsHeadings
                                        ]?.linkHref,
                                    );
                                    const gymBenefit = includedBenefits[
                                        heading as BenefitsHeadings
                                    ]?.display as ReactElement<any, any>;

                                    const gymBenefitNot24Hours =
                                        !isOpen24Hours &&
                                        (title === 'standard' ||
                                            title === 'membershipStudent') &&
                                        gymBenefit.props['aria-label'] &&
                                        gymBenefit.props['aria-label'].includes(
                                            '24/7',
                                        );

                                    if (gymBenefitNot24Hours) {
                                        const cellData = {
                                            id,
                                            row,
                                            heading,
                                            title,
                                        };

                                        const cellState = {
                                            selectedProduct,
                                            hoveredProduct,
                                            includedBenefits,
                                            isProductDisabled,
                                            onCommerceGymPage,
                                        };

                                        const cellActions = {
                                            handleMouseEnter,
                                            handleMouseLeave,
                                            setSelectedProduct,
                                            selectProduct,
                                            scrollToRelatedSection,
                                        };

                                        return (
                                            <StandardAccessCell
                                                key={`${title}-${id}-table-cell`}
                                                cell={cellData}
                                                state={cellState}
                                                actions={cellActions}
                                            />
                                        );
                                    }

                                    return (
                                        <StyledTableCell
                                            theme={theme}
                                            data-ab-test={
                                                title === hoveredProduct &&
                                                title !== selectedProduct
                                                    ? `${title}-hovered`
                                                    : title === selectedProduct
                                                    ? `${title}-selected`
                                                    : undefined
                                            }
                                            $selectedProduct={
                                                title === selectedProduct
                                                    ? title
                                                    : undefined
                                            }
                                            $hoveredProduct={
                                                title === hoveredProduct &&
                                                title !== selectedProduct
                                                    ? title
                                                    : undefined
                                            }
                                            $isDesktop={isDesktop}
                                            onMouseEnter={() =>
                                                handleMouseEnter(title)
                                            }
                                            onMouseLeave={() =>
                                                handleMouseLeave(title)
                                            }
                                            onClick={() => {
                                                selectProduct(row);
                                                setSelectedProduct(title);
                                            }}
                                            key={id}
                                            align="center"
                                            rowSpan={
                                                includedBenefits[
                                                    heading as BenefitsHeadings
                                                ].rowSpan
                                            }
                                            disabled={isProductDisabled}
                                        >
                                            {view}
                                        </StyledTableCell>
                                    );
                                })}
                            </StylesTableRow>
                        ))}
                        <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit
                            classes={{
                                wrapper: overridesCollapseClasses.wrapper,
                                root: overridesCollapseClasses.root,
                                wrapperInner:
                                    overridesCollapseClasses.wrapperInner,
                            }}
                        >
                            {hiddenAdditionalBenefits.map(heading => (
                                <TableRow key={heading}>
                                    <StyledBenefitsColumn
                                        theme={theme}
                                        component="th"
                                        scope="row"
                                        role="rowheader"
                                        $innerHeader
                                    >
                                        {heading}
                                    </StyledBenefitsColumn>
                                    {productsWithDetails.map(row => {
                                        if (
                                            !row.includedBenefits[
                                                heading as BenefitsHeadings
                                            ]
                                        ) {
                                            return null;
                                        }

                                        const { id, includedBenefits, title } =
                                            row;
                                        const isProductDisabled =
                                            shouldDisableProduct(
                                                row,
                                                disabledProduct,
                                            );

                                        return (
                                            <StyledTableCell
                                                theme={theme}
                                                $selectedProduct={
                                                    title === selectedProduct
                                                        ? title
                                                        : undefined
                                                }
                                                $hoveredProduct={
                                                    title === hoveredProduct &&
                                                    title !== selectedProduct
                                                        ? title
                                                        : undefined
                                                }
                                                onMouseEnter={() =>
                                                    handleMouseEnter(title)
                                                }
                                                onMouseLeave={() =>
                                                    handleMouseLeave(title)
                                                }
                                                onClick={() => {
                                                    selectProduct(row);
                                                    setSelectedProduct(title);
                                                }}
                                                key={id}
                                                align="center"
                                                rowSpan={
                                                    includedBenefits[
                                                        heading as BenefitsHeadings
                                                    ].rowSpan
                                                }
                                                disabled={isProductDisabled}
                                            >
                                                {
                                                    includedBenefits[
                                                        heading as BenefitsHeadings
                                                    ].display
                                                }
                                            </StyledTableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </Collapse>
                        {hiddenSelectBenefits.map(heading => (
                            <TableRow key={heading}>
                                <StyledBenefitsColumn
                                    theme={theme}
                                    component="th"
                                    scope="row"
                                    role="rowheader"
                                    $innerHeader
                                >
                                    {heading}
                                </StyledBenefitsColumn>
                                {productsWithDetails.map(row => {
                                    const { id, includedBenefits, title } = row;
                                    const isProductDisabled =
                                        shouldDisableProduct(
                                            row,
                                            disabledProduct,
                                        );

                                    if (
                                        !includedBenefits[
                                            heading as BenefitsHeadings
                                        ]
                                    ) {
                                        return null;
                                    }

                                    if (
                                        heading === 'Additional benefits' &&
                                        title === 'ultimate'
                                    ) {
                                        return (
                                            <StyledTableCell
                                                theme={theme}
                                                $selectedProduct={
                                                    title === selectedProduct
                                                        ? title
                                                        : undefined
                                                }
                                                $hoveredProduct={
                                                    title === hoveredProduct &&
                                                    title !== selectedProduct
                                                        ? title
                                                        : undefined
                                                }
                                                onMouseEnter={() =>
                                                    handleMouseEnter(title)
                                                }
                                                onMouseLeave={() =>
                                                    handleMouseLeave(title)
                                                }
                                                key={id}
                                                align="center"
                                                rowSpan={
                                                    includedBenefits[heading]
                                                        .rowSpan
                                                }
                                                disabled={isProductDisabled}
                                            >
                                                <StyledExpanderPinoButton
                                                    fullWidth
                                                    onClick={() =>
                                                        setOpen(
                                                            currentValue =>
                                                                !currentValue,
                                                        )
                                                    }
                                                >
                                                    {open ? (
                                                        <>
                                                            <StyledSVGOutline
                                                                $shouldRotate={
                                                                    open
                                                                }
                                                            >
                                                                <Icon
                                                                    name="plus"
                                                                    color={
                                                                        palette
                                                                            .common
                                                                            .blue
                                                                    }
                                                                    size={18}
                                                                    accessibleTitle="close button for additional benefits expander"
                                                                />
                                                            </StyledSVGOutline>
                                                            show less
                                                        </>
                                                    ) : (
                                                        <>
                                                            <StyledSVGOutline
                                                                $shouldRotate={
                                                                    false
                                                                }
                                                            >
                                                                {
                                                                    includedBenefits[
                                                                        heading
                                                                    ].display
                                                                }
                                                            </StyledSVGOutline>
                                                            {getHiddenBenefitCount()}{' '}
                                                            more
                                                        </>
                                                    )}
                                                </StyledExpanderPinoButton>
                                            </StyledTableCell>
                                        );
                                    }

                                    return (
                                        <StyledTableCell
                                            theme={theme}
                                            $selectedProduct={
                                                title === selectedProduct
                                                    ? title
                                                    : undefined
                                            }
                                            $hoveredProduct={
                                                title === hoveredProduct &&
                                                title !== selectedProduct
                                                    ? title
                                                    : undefined
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(title)
                                            }
                                            onMouseLeave={() =>
                                                handleMouseLeave(title)
                                            }
                                            onClick={() => {
                                                selectProduct(row);
                                                setSelectedProduct(title);
                                            }}
                                            key={id}
                                            align="center"
                                            rowSpan={
                                                includedBenefits[
                                                    heading as BenefitsHeadings
                                                ].rowSpan
                                            }
                                            disabled={isProductDisabled}
                                        >
                                            {
                                                includedBenefits[
                                                    heading as BenefitsHeadings
                                                ].display
                                            }
                                        </StyledTableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                    {showFooter && (
                        <TableFooter>
                            <TableRow>
                                <StyledBenefitsColumn theme={theme} />
                                {productsWithDetails.map(product => {
                                    const { title, id, price } = product;
                                    const isTitleSelectedProduct =
                                        title === selectedProduct;
                                    const isProductDisabled =
                                        shouldDisableProduct(
                                            product,
                                            disabledProduct,
                                        );

                                    return (
                                        <StyledTableFooterCell
                                            theme={theme}
                                            $selectedProduct={
                                                isTitleSelectedProduct
                                                    ? title
                                                    : undefined
                                            }
                                            data-ab-test={
                                                title === hoveredProduct &&
                                                title !== selectedProduct
                                                    ? `${title}-hovered`
                                                    : isTitleSelectedProduct
                                                    ? `${title}-selected`
                                                    : undefined
                                            }
                                            $hoveredProduct={
                                                title === hoveredProduct &&
                                                title !== selectedProduct
                                                    ? title
                                                    : undefined
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(title)
                                            }
                                            onMouseLeave={() =>
                                                handleMouseLeave(title)
                                            }
                                            onClick={() => {
                                                selectProduct(product);
                                                setSelectedProduct(title);
                                            }}
                                            key={id}
                                            align="center"
                                            variant="footer"
                                            disabled={isProductDisabled}
                                        >
                                            <PinoButton
                                                aria-label={`${title} from ${decimalToPoundsAndPence(
                                                    price.value,
                                                )} a month`}
                                                size="small"
                                                data-testid={`select-${title}-plan`}
                                                selected={
                                                    isTitleSelectedProduct
                                                }
                                                disabled={isProductDisabled}
                                            >
                                                {isTitleSelectedProduct ? (
                                                    <Icon
                                                        name="tick"
                                                        color={
                                                            getPinoColor(title)
                                                                .contentColor
                                                        }
                                                        size={18}
                                                    />
                                                ) : (
                                                    'Select'
                                                )}
                                            </PinoButton>
                                        </StyledTableFooterCell>
                                    );
                                })}
                            </TableRow>
                        </TableFooter>
                    )}
                </StyledTable>
            </TableContainer>
            <PinoOverlay
                title="your membership benefits"
                open={isModalOpen}
                handleClose={handleClose}
            />
        </>
    );
};

export const renderCellContent = (
    onCommerceGymPage: boolean,
    displayContent: ReactNode,
    linkHref: string | undefined,
) => {
    if (onCommerceGymPage && linkHref) {
        return (
            <StyledAnchorButton
                type="button"
                onClick={() => scrollToRelatedSection(linkHref)}
            >
                {displayContent}
            </StyledAnchorButton>
        );
    }

    return displayContent;
};
