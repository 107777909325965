import { Divider, Table, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { palette, Theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { Heading } from '../Heading';
import { PinoButton } from '../PinoButton';
import { PinoProductTitle } from '@tgg/common-types';

type TableCellProperties = {
    $selectedProduct?: PinoProductTitle;
    $hoveredProduct?: PinoProductTitle;
    $isDesktop?: boolean;
    theme: Theme;
    disabled?: boolean;
};

type ProductHeaderProperties = {
    $selectedProduct?: PinoProductTitle;
    $isKickerOfferRibbon: boolean;
    theme: Theme;
    title: PinoProductTitle;
    disabled?: boolean;
};

type ProductTitleProperties = {
    $isStudentPinoTable?: boolean;
};

type ProductDividerProperties = {
    $product: PinoProductTitle;
};

type ProductFooterProperties = {
    $selectedProduct?: PinoProductTitle;
    theme: Theme;
    disabled?: boolean;
};

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
`;

type BenefitsCellProperties = {
    theme: Theme;
    $header?: boolean;
    $innerHeader?: boolean;
};

export const StyledBenefitsColumn = styled(TableCell, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<BenefitsCellProperties>`
    max-width: 11rem;
    color: ${palette.common.blue};
    border-top: 0;
    border-bottom-color: ${palette.common.white};
    border-left: 0;

    ${({ $header, theme }: BenefitsCellProperties) => {
        if ($header) {
            return `
                border-radius: 1rem 1rem 0 0;
                vertical-align: bottom;
                font-family: thegymgroupsans-Headline, sans-serif;
                padding-right: 0;
            `;
        }

        return `
            border-radius: 0;
            padding-top: ${theme.spacing(10)};
            padding-bottom: ${theme.spacing(10)};

            ${theme.breakpoints.up('desktop')} {
                padding-top: ${theme.spacing(16)};
                padding-bottom: ${theme.spacing(16)};
            }
        `;
    }}

    ${({ $innerHeader }: BenefitsCellProperties) => {
        if ($innerHeader) {
            return `
                background: ${palette.grey[50]};
            `;
        }

        return `
            background: ${palette.grey[100]};
        `;
    }}

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 24rem;
    }
`;

export const StyledProductHeader = styled(TableCell, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<ProductHeaderProperties>`
    position: relative;
    max-width: 9rem;
    padding: 1rem 0 0 0;
    text-transform: lowercase;
    vertical-align: bottom;
    border-bottom: 0;
    border-radius: 1rem 1rem 0 0;

    ${({ disabled }: ProductHeaderProperties) => {
        if (disabled) {
            return `
                opacity: 0.5;
                cursor: not-allowed;
            `;
        }

        return ``;
    }}

    ${({ title }: ProductHeaderProperties) => {
        const color = getPinoColor(title);

        return `
            background-color: ${color.primary};
            color: ${palette.common.blue};
        `;
    }}

    ${({ $selectedProduct }: TableCellProperties) => {
        if ($selectedProduct) {
            const color = getPinoColor($selectedProduct);

            return `
                border-inline-color: ${color.primary};
                border-bottom-color: ${color.primary};
            `;
        }

        return ``;
    }}

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 20rem;
    }

    ${({ $isKickerOfferRibbon }: ProductHeaderProperties) =>
        $isKickerOfferRibbon ? 'height: 24rem;' : ''}
`;

export const StylesTableRow = styled(TableRow, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{ $showFooter?: boolean }>`
    ${({ $showFooter }) =>
        !$showFooter
            ? `
                  &:last-of-type {
                      td {
                          border-bottom-width: 0.3rem;
                          border-bottom-style: solid;
                      }
                  }
              `
            : ''}
`;

export const StyledTableCell = styled(TableCell, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<TableCellProperties>`
    position: relative;
    max-width: 9rem;
    padding: 1rem;
    border-inline-width: ${({ theme }) => theme.spacing(3)};
    border-inline-style: solid;
    color: ${palette.common.blue};
    border-bottom: none;

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 0.1rem solid ${palette.grey[100]};
        content: '';
    }

    a {
        color: ${palette.common.blue};
        text-decoration: underline;
        cursor: pointer;
    }

    ${({ disabled }: TableCellProperties) => {
        if (disabled) {
            return `
                opacity: 0.5;
                cursor: not-allowed;
            `;
        }

        return ``;
    }}

    ${({ $selectedProduct, $isDesktop }: TableCellProperties) => {
        if ($selectedProduct) {
            const color = getPinoColor($selectedProduct, $isDesktop);

            return `
                border-inline-color: ${color.primary};
                background-color: ${color.alternate};
                border-right: 3px solid ${color.primary};
                border-color: ${color.primary};

                &:before {
                    border-bottom: none;
                }
            `;
        }

        return `
            border-inline-color: transparent;
        `;
    }}

    ${({ $hoveredProduct }: TableCellProperties) => {
        if ($hoveredProduct) {
            const borderColour = getPinoColor($hoveredProduct).primary;

            return `
                border-right: 0.1rem solid ${borderColour};
                border-left: 0.1rem solid ${borderColour};
            `;
        }

        return ``;
    }}

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: 20rem;
        border-inline-width: ${({ theme }) => theme.spacing(3)};
    }
`;

export const StyledTableFooterCell = styled(StyledTableCell, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<ProductFooterProperties>`
    border-bottom: 0.3rem solid transparent;
    &:before {
        border-bottom: none;
    }

    ${({ disabled }: ProductFooterProperties) => {
        if (disabled) {
            return `
                opacity: 0.5;
                cursor: not-allowed;
            `;
        }

        return ``;
    }}

    ${({ $selectedProduct }: TableCellProperties) => {
        if ($selectedProduct) {
            return `
                background-color: ${getPinoColor($selectedProduct).primary};
            `;
        }

        return ``;
    }}

    ${({ $hoveredProduct }: TableCellProperties) => {
        if ($hoveredProduct) {
            const borderColour = getPinoColor($hoveredProduct).primary;

            return `
                border-right: 0.3rem solid ${borderColour};
                border-left: 0.3rem solid ${borderColour};
                border-bottom: 0.3rem solid ${borderColour};
            `;
        }

        return ``;
    }}
`;

export const StyledTable = styled(Table)`
    table-layout: auto;
    border-collapse: separate;
    border-spacing: ${({ theme }) => theme.spacing(2)} 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        border-spacing: ${({ theme }) => theme.spacing(3)} 0;
    }
`;

export const StyledBoldSpanText = styled('span')`
    font-size: 1.6rem;
    font-family: thegymgroupsans-Headline, sans-serif;
`;

export const StyledProductTitle = styled('div')<ProductTitleProperties>`
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
    font-family: thegymgroupsans-Headline, sans-serif;

    ${({ $isStudentPinoTable }: ProductTitleProperties) => {
        if ($isStudentPinoTable) {
            return `
                color: ${palette.common.white};
            `;
        }

        return ``;
    }}
`;

export const StyledButtonWrapperForIcon = styled('button')`
    margin-left: 1rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-left: 1.5rem;
    }
    padding: 0;
    vertical-align: middle;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const StyledSVGOutline = styled('span')<{
    $shouldRotate: boolean;
}>`
    display: flex;
    padding: 0.2rem;
    border: 0.1rem solid ${palette.common.blue};
    border-radius: 50%;
    transform: ${({ $shouldRotate }) =>
        $shouldRotate ? 'rotate(45deg)' : 'rotate(0deg)'};
`;

export const StyledExpanderPinoButton = styled(PinoButton)`
    padding: 0;
    background: none;
    border: none;

    &.MuiButtonBase-root:hover,
    &.MuiButtonBase-root:active,
    &.MuiButtonBase-root:focus {
        color: ${palette.common.blue};
        background: none;
        border: 0;
        outline: 0;
    }

    .MuiButton-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 8rem;
        padding: 0.5rem;
    }
`;

export const useTableCollapseOverrides = makeStyles({
    root: {
        height: '0',
        overflow: 'hidden',
        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        display: 'contents',
    },
    wrapper: {
        display: 'contents',
    },
    wrapperInner: {
        width: '100%',
        display: 'contents',
    },
});

export const StyledCaption = styled('caption')`
    && {
        margin-bottom: ${({ theme }) => theme.spacing(30)};
        padding: 0 ${({ theme }) => theme.spacing(30)};
        color: ${palette.common.blue};
        font-size: 1.4rem;
        font-family: thegymgroupsans-Headline, sans-serif;
        line-height: 1.4rem;
        caption-side: top;
        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            text-align: center;
        }
    }
`;

export const StyledProductHeaderJoiningFee = styled('p')`
    margin: 0;
    padding: 0;
`;

export const StyledProductHeaderPrices = styled('div')`
    && {
        margin: 0 0.3rem 0.3rem 0.3rem;
        padding: 1rem 0;
        font-size: 1rem;
        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
            font-size: 1.6rem;
        }
        background: ${palette.common.white};
    }
`;

export const StyledAMonthText = styled('div')`
    font-size: 1.4rem;
    font-family: thegymgroupsans-Headline, sans-serif;
`;

export const StyledDivider = styled(Divider, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<ProductDividerProperties>`
    width: 20%;
    margin: 0.5rem auto;

    ${({ $product }: ProductDividerProperties) => {
        return `
                background-color: ${getPinoColor($product).primary};
            `;
    }}
`;

export const StyledAnchorButton = styled('button')`
    padding: 0;
    color: ${({ theme }: { theme: Theme }) => theme.palette.common.blue};
    font-size: inherit;
    line-height: 2.2rem;
    text-decoration: underline;
    background: none;
    border: 0;
    cursor: pointer;
`;

export const StyledKickerOfferRibbon = styled('div')`
    position: absolute;
    top: -0.9rem;
    left: -0.9rem;
    z-index: 1;
    width: 15rem;
    height: 15rem;
    overflow: hidden;

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: -1rem;
        right: 0.1rem;
        border: 1rem solid transparent;
        border-bottom: 1rem solid ${palette.common.blue};
    }

    &::after {
        bottom: 0.1rem;
        left: -1rem;
        border: 1rem solid transparent;
        border-right: 1rem solid ${palette.common.blue};
    }
`;

export const StyledKickerOfferRibbonText = styled('span')`
    position: absolute;
    top: 3rem;
    right: -2.5rem;
    display: block;
    width: 22.5rem;
    padding: 1.3rem 0;
    color: #fff;
    font: 700 1.3rem/1 'thegymgroupsans-Headline', sans-serif;
    text-align: center;
    background-color: #0f0066;
    transform: rotate(-45deg);
`;
